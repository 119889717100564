
import { computed, defineComponent, ref } from "vue";
import { useI18n } from "vue-i18n";
export default defineComponent({
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: 480,
    },
    info: {
      type: String,
      default: "Unable to access at present, please contact us",
    },
  },
  setup(props, context) {
    const visible = computed(() => props.visible);
    const toCancle = () => {
      context.emit("toCancle");
    };
    return {
      visible,
      toCancle,
    };
  },
});
