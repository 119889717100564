import axios from 'axios';
import store from '@/store';

const baseURL = 'https://data-service.afirstsoft.cn/'; // 目标测试地址
const service = axios.create({
  baseURL,
  timeout: 10 * 1000,
  headers: {},
});

//获取购物车链接
export const getMoins = (data: any) => {
  return service({
    headers: {
      Authorization: localStorage.getItem('authorization'),
    },
    url: `/api/v1/goods`,
    method: 'get',
    params: data,
  });
};
