
import {
  defineComponent,
  onMounted,
  ref,
  computed,
  onUpdated,
  nextTick,
  watch,
} from "vue";
// import { getCartUrl } from "@/api/login";
import { useStore } from "vuex";
import { gaEvent } from "@/utils/format";
import { useI18n } from "vue-i18n";
import purchaseDialog from "./tabItem/purchaseDialog.vue";
import contactDialog from "./tabItem/contactDialog.vue";
import { getMoins } from "@/api/cart";
export default defineComponent({
  components: {
    purchaseDialog,
    contactDialog,
  },
  setup() {
    const store = useStore();
    let pick = ref(0);
    let temp = ref();
    interface CoinItem {
      price: number;
      cycle_type: number;
    }
    const allCoins = ref<CoinItem[]>([]);//1月 3月 1年的价格
    const { t } = useI18n();
    const choseMeal = (menu: number) => {
      pick.value = menu;
      console.log("pick.value", pick.value);
    };
    const conins = ref([] as number[])
    const lang = computed(() => store.state.currentLang);
    const feature1 = ref([
      t('famikeep210.WhatsApp Chat'),
      t('famikeep210.Location Reminder'),
      t('famikeep210.App Activity'),
      t('famikeep210.Live Location'),
      t('famikeep210.App Blocker'),
      t('famikeep210.Safe Search'),
      t('famikeep210.Monitor Social Apps'),
      t('famikeep210.Web Filter'),
      t('famikeep200.Geofence'),
      t('famikeep210.Browser History'),
      t('famikeep210.Location History'),
      t('famikeep210.Content Detection'),
    ])
    const feature2 = [
      'kakapo',
      'WhatsApp',
      'TikTok',
      'Youtube',
      'Tinder',
      'Instagram',
      'Telegram',
      'Snapchat',
      'Kik',
      'Facebook',
      'WeChat',
      'LINE'
    ]
    const feature3 = ref([
      t('famikeep210.All SMS'),
      t('famikeep210.Call History'),
      t('famikeep210.Photos'),
      t('famikeep210.Call Recording'),
      t('famikeep210.Video'),
      t('famikeep210.Contacts'),
      t('famikeep210.Calendar'),
      t('famikeep210.Messages'),
      t('famikeep210.Documents'),
      t('famikeep210.Call Logs'),
      t('famikeep210.Record Screen'),
      t('famikeep210.Chat History'),
    ])
    const payUl1 = ref(0);
    const payUl2 = ref(0);
    const payUl3 = ref(0);
    const selectedDeviceType = ref(0) //默认选中安卓
    // 控制去购买弹窗的显隐
    const showPurchase = ref(false);
    const closePurchase = () => {
      showPurchase.value = false;
      showContact.value = true;
    };

    // 控制购买出错的弹窗的显隐
    const showContact = ref(false);
    const closeContact = () => {
      showContact.value = false;
    };
    const currentPlan = ref({
      license: "",
      newPrice: "",
      formerPrice: "",
    });

    const choosePlan = ref(false);
    const deviceType = computed(() => {
      return store.state.deviceType
    })

    const goToBuy = async (data: number) => {
      if (store.state.payFrom == 0) {
        gaEvent("Click_BuyButton", {
          event_category: "Buy",
          event_label: "Click_BuyButton_PurchaseNewPlan",
        });
      } else {
        gaEvent("Click_BuyButton", {
          event_category: "Buy",
          event_label: "Click_BuyButton_Adddevice",
        });
      }
      if (data == 1) {
        gaEvent("Click_BuyButton_LicenseChoose", {
          event_category: "Buy",
          event_label: "Month",
        });
        store.commit("setPayMenu", "Month");
        temp.value = "monthly";
        // 发送网络请求
        // showPurchase.value = true;
        currentPlan.value.license = t("payPage.one");
        currentPlan.value.newPrice = "$19.95";
        currentPlan.value.formerPrice = "$29.95";
        window.open("https://order.famikeep.com/api/v1/cart?g=102214&l=1&currency=USD")

      } else if (data == 2) {
        gaEvent("Click_BuyButton_LicenseChoose", {
          event_category: "Buy",
          event_label: "Quarter",
        });
        store.commit("setPayMenu", "Quarter");
        temp.value = "quarterly";
        // showPurchase.value = true;
        choosePlan.value = true;
        currentPlan.value.license = t("payPage.three");
        currentPlan.value.newPrice = "$34.95";
        currentPlan.value.formerPrice = "$59.95";
        window.open("https://order.famikeep.com/api/v1/cart?g=102215&l=1&currency=USD")

      } else {
        gaEvent("Click_BuyButton_LicenseChoose", {
          event_category: "Buy",
          event_label: "Year",
        });
        store.commit("setPayMenu", "Year");
        temp.value = "yearly";
        // showPurchase.value = true;
        currentPlan.value.license = t("payPage.year");
        currentPlan.value.newPrice = "$83.35";
        currentPlan.value.formerPrice = "$119.95";
        window.open("https://order.famikeep.com/api/v1/cart?g=102216&l=1&currency=USD")

      }

      // let params = {
      //   type: temp.value,
      // }

      // // // 发送网络请求，获取购物车地址
      // let result = await getCartUrl(params)
      // if (result.data.code == 200) {
      //   // 在新窗口打开付费页面
      //   console.log('url:', result.data.data.url)

      //   window.open(result.data.data.url)
      // }
    };

    const toPurchase = (requestDate: Object) => {
      console.log("requestDate", requestDate, currentPlan.value.license);
      // 获取参数发送网络请求

      // 在这拿到网络请求的结果，然后对不同的错误码传递不一样的info参数
    };
    //切换货币价格
    const changeMoin = async () => {
      let params = {
        pid: 5881,
      }

      // // 发送网络请求，获取购物车地址
      let result: any = await getMoins(params)
      // 在新窗口打开付费页面
      // console.log('url:', result.data.data.url)
      console.log("result.data[0]:", result.data[0].price);
      // let price1 = result.data[0].price
      // let price2 = result.data[1].price
      // let price3 = result.data[2].price
      result.data.forEach((element: any) => {
        let obj = {
          price: element.price,
          cycle_type: element.cycle_type
        }
        allCoins.value.push(obj)
      });
      allCoins.value.sort((a: any, b: any) => {
        return a.cycle_type - b.cycle_type;
      });
      allCoins.value.forEach((item: any) => {
        conins.value.push(item.price)
      })

      console.log("allCoins.value.price:", allCoins.value[0].price);

      // window.open(result.data.data.url)
    }
    //切换选中设备
    const changeSelectedDeviced = (data: number) => {
      selectedDeviceType.value = data
      console.log("selectedDeviceType.value:", selectedDeviceType.value);

    }



    watch([payUl1, payUl2, payUl3], async (newVal, oldVal) => {
      console.log("newVal:", newVal, oldVal);
      let maxHeight = Math.max(payUl1.value, payUl2.value, payUl3.value)
      console.log("maxHeight:", maxHeight);
      if (maxHeight > 0) {
        console.log(document.querySelectorAll(".ul1")[0]);

        (document.querySelectorAll(".ul1")[0] as HTMLElement).style.height = maxHeight + 'px';
        (document.querySelectorAll(".ul2")[0] as HTMLElement).style.height = maxHeight + 'px';
        (document.querySelectorAll(".ul3")[0] as HTMLElement).style.height = maxHeight + 'px';
      }

    })
    onMounted(() => {
      changeMoin()
      if (deviceType.value) {
        changeSelectedDeviced(1)
      }
      try {
        // 在购买页刷新的时候，onMounted会比建立ws连接更快，所以不能在建立连接的时候send，所以加一个延迟
        setTimeout(() => {
          store.state.wsObj.send("permission");
        }, 5000);
      } catch (err) {
        console.log(err);
      }
      payUl1.value = document.querySelectorAll(".ul1")[0].clientHeight;
      payUl2.value = document.querySelectorAll(".ul2")[0].clientHeight;
      payUl3.value = document.querySelectorAll(".ul3")[0].clientHeight;
      console.log("payUl1.value:", payUl1.value, payUl2.value, payUl3.value);
    });
    return {
      pick,
      lang,
      showPurchase,
      currentPlan,
      showContact,
      choosePlan,
      goToBuy,
      choseMeal,
      closePurchase,
      closeContact,
      toPurchase,
      changeMoin,
      allCoins,
      conins,
      selectedDeviceType,
      changeSelectedDeviced,
      feature1,
      feature2,
      feature3
    };
  },
});
